import { Box } from "@mui/material"
import { useCallback } from "react"
// components
import Icon from "shared/components/Icon"
// styles
import { SLink, StyledNavLink } from "./styles"
// types
import { NavLinkProps } from "./types"

const NavLink = ({ to, children, icon, disableActive, sxLink }: NavLinkProps) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!to) {
        event.preventDefault()
      }

      if (/^(http|https):\/\//.test(to)) {
        event.preventDefault()
        window.open(to, "_blank", "noopener,noreferrer")
      }
    },
    [to]
  )

  return (
    <StyledNavLink to={to} onClick={handleClick} data-testid={icon}>
      {({ isActive }: { isActive: boolean }) => (
        <SLink isActive={isActive && !disableActive} sx={sxLink}>
          {icon && (
            <Box sx={[(theme) => ({ mr: 6, [theme.breakpoints.down("sm")]: { mr: 0 } })]}>
              <Icon name={icon} />
            </Box>
          )}
          {children}
        </SLink>
      )}
    </StyledNavLink>
  )
}

export default NavLink
