import { useCallback, useEffect, useState } from "react"
// types
import { Swiper as TSwiper } from "swiper/types"

const useCarousel = () => {
  const [swiper, setSwiper] = useState<TSwiper | null>(null)
  const [isLastSlide, setLastSlide] = useState<boolean | undefined>(swiper?.isEnd)
  const [isFirstSlide, setFirstSlide] = useState<boolean | undefined>(swiper?.isBeginning)

  const swiperInit = (swiper: TSwiper) => {
    setFirstSlide(swiper.isBeginning)
    setLastSlide(swiper.isEnd)
    setSwiper(swiper)
  }

  const handleNextSlide = useCallback(() => {
    !isLastSlide && swiper?.slideNext()
  }, [isLastSlide, swiper])

  const handlePrevSlide = useCallback(() => {
    !isFirstSlide && swiper?.slidePrev()
  }, [isFirstSlide, swiper])

  const handleOnToFromEdge = useCallback(() => {
    setFirstSlide(swiper?.isBeginning)
    setLastSlide(swiper?.isEnd)
  }, [swiper])

  useEffect(() => {
    if (!swiper) return

    handleOnToFromEdge()
  }, [swiper?.isBeginning, swiper?.isEnd, handleOnToFromEdge])

  return {
    isLastSlide,
    isFirstSlide,
    swiperInit,
    handleNextSlide,
    handlePrevSlide,
    handleOnToFromEdge,
  }
}

export default useCarousel
