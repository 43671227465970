import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
// services
import { userConfirmation, UserConfirmationResponse, USER_KEYS } from "api/user"
import { getBeforeLoginUrl, setNeedOnboardingFrom } from "services/auth"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// constants
import { VERIFICATION_ERROR, HOME } from "constants/routes"
import { OPEN_MODAL } from "constants/searchParams"
import { MODALS } from "constants/modals"

type UseCheckEmailConfirmation = {
  confirmationToken?: string
  email?: string
}

const useCheckEmailConfirmation = ({ confirmationToken = "", email = "" }: UseCheckEmailConfirmation) => {
  const navigate = useNavigate()
  const { setAuthToken } = useAuthenticationContext()

  const { data: userConfirmationResponse } = useQuery<UserConfirmationResponse>({
    queryKey: [USER_KEYS.USER_CONFIRMATION],
    queryFn: () => userConfirmation({ confirmationToken, email }),
    enabled: !!email && !!confirmationToken,
  })

  if (userConfirmationResponse) {
    userConfirmationResponse?.jwt && setAuthToken(userConfirmationResponse?.jwt, email)

    if (!userConfirmationResponse?.userConfirmed) navigate(VERIFICATION_ERROR, { replace: true })
    const beforeLoginUrl = getBeforeLoginUrl()
    const searchParams = new URLSearchParams()
    setNeedOnboardingFrom(String(Date.now()))
    searchParams.set(OPEN_MODAL, MODALS.AUTH.WELCOME)
    navigate(`${beforeLoginUrl || HOME}?${searchParams.toString()}`, { replace: true })
  }
}

export default useCheckEmailConfirmation
