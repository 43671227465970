import { Box, Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { SwiperSlide } from "swiper/react"
// components
import { CourseCard, ViewAllCard, BrandCard } from "shared/components/cards"
import Carousel from "shared/components/Carousel"
// constants
import { coursePath, courseEpisodePath } from "constants/routes"
// types
import { CardsCarouselProps } from "./types"

const SkeletonLoadingRow = () => (
  <>
    <SwiperSlide>
      <Skeleton sx={{ borderRadius: "16px" }} variant="rounded" width={405} height={260} />
    </SwiperSlide>
    <SwiperSlide>
      <Skeleton sx={{ borderRadius: "16px" }} variant="rounded" width={190} height={260} />
    </SwiperSlide>
    <SwiperSlide>
      <Skeleton sx={{ borderRadius: "16px" }} variant="rounded" width={405} height={260} />
    </SwiperSlide>
    <SwiperSlide>
      <Skeleton sx={{ borderRadius: "16px" }} variant="rounded" width={405} height={260} />
    </SwiperSlide>
  </>
)

const CardsCarousel = ({ isLoading, cards, showAllUrl, title = "", sx }: CardsCarouselProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={sx}>
      <Carousel title={title}>
        {isLoading && SkeletonLoadingRow()}
        {!isLoading && (
          <>
            {cards.map(
              (
                {
                  title,
                  backgroundImage,
                  backgroundHoverMedia,
                  backgroundImageHash,
                  circleMaxValue,
                  circleCurrentValue,
                  episodesCount,
                  episodesWatched,
                  topTags,
                  bottomTags,
                  variant,
                  courseId,
                  episodeId,
                },
                idx
              ) => {
                if (variant === "brandPrimary" || variant === "brandSecondary")
                  return (
                    <BrandCard
                      key={title + idx}
                      redirectUrl="/home"
                      title={title}
                      backgroundImage={backgroundImage}
                      buttonText="some button text"
                      variant={variant}
                    />
                  )

                return (
                  <SwiperSlide key={title + idx}>
                    <CourseCard
                      redirectUrl={episodeId ? courseEpisodePath(courseId, episodeId) : coursePath(courseId)}
                      title={title}
                      backgroundImage={backgroundImage}
                      backgroundImageHash={backgroundImageHash}
                      backgroundHoverMedia={backgroundHoverMedia}
                      circleMaxValue={circleMaxValue}
                      circleCurrentValue={circleCurrentValue}
                      episodesCount={episodesCount}
                      episodesWatched={episodesWatched}
                      topTags={topTags}
                      bottomTags={bottomTags}
                      variant={variant}
                    />
                  </SwiperSlide>
                )
              }
            )}
            {showAllUrl && (
              <SwiperSlide>
                <ViewAllCard
                  redirectUrl={showAllUrl}
                  title={t("home:cardsCarousel:showAll")}
                  cardSize="viewAllCourse"
                />
              </SwiperSlide>
            )}
          </>
        )}
      </Carousel>
    </Box>
  )
}

export default CardsCarousel
