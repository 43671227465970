// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"
// types
import { NavButtonStyleProps, NavButtonProps } from "./types"

export const CarouselWrapper = customStyled("div")(({ theme: { breakpoints } }) => ({
  overflow: "hidden",
  padding: "0 40px",

  [breakpoints.down("sm")]: {
    padding: "0 16px",
  },

  "& > .swiper": {
    overflow: "visible",
  },
  "& .swiper-slide": {
    width: "auto",
  },
}))

export const CarouselHeaderWrapper = customStyled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  marginBottom: spacing(7),

  [breakpoints.down("sm")]: {
    marginBottom: spacing(4),
  },

  "& > h4": {
    width: "-webkit-fill-available",
  },
}))

export const NavButtons = customStyled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",

  "& > div:last-child": {
    marginLeft: spacing(3),
  },
}))

export const NavButton = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp<NavButtonStyleProps>(["disable"], prop),
})<NavButtonProps>(({ theme: { palette }, disable, onClick }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  "& > div": {
    height: "24px",
  },

  backgroundColor: palette.black.lacquer["800"],
  "&:hover": {
    ...(!disable && { backgroundColor: palette.black.lacquer["700"] }),
  },
  ...(onClick && { cursor: "pointer" }),
  ...(disable && { cursor: "auto", opacity: 0.6 }),
}))
