import { useMutation } from "@tanstack/react-query"
import { useCallback, useState } from "react"
// api
import { kalturaSessionLogin, KalturaSessionLoginResponse } from "api"
// utils
import LocalStorage from "utils/localStorage"
// constants
import { KALTURA_TOKEN } from "constants/user"
import { getKalturaToken } from "services/auth"

const useKalturaSession = () => {
  const [kalturaToken, setKalturaToken] = useState(getKalturaToken() || "")
  const { mutate: fetchKalturaToken } = useMutation<KalturaSessionLoginResponse, unknown, string>({
    mutationFn: (userId) => kalturaSessionLogin(userId),
    onSuccess: (kalturaToken) => {
      LocalStorage.set(KALTURA_TOKEN, kalturaToken)
      setKalturaToken(kalturaToken || "")
    },
    onError: (err: any) => {
      console.error("Kaltura token was not received:", err)
    },
  })

  const handleSetKalturaToken = useCallback(async ({ userId }: { userId: string }) => {
    await fetchKalturaToken(userId)
  }, [])

  const handleRemoveKalturaToken = useCallback(() => {
    LocalStorage.remove(KALTURA_TOKEN)
  }, [])

  return {
    kalturaToken,
    handleSetKalturaToken,
    handleRemoveKalturaToken,
  }
}

export default useKalturaSession
