import { Box } from "@mui/material"
// hooks
import { useCourses } from "pages/hooks/useCourses"
import { useElementVisible } from "hooks/useElementVisible"
// routes
import { detailPath } from "constants/routes"
// components
import CardsCarousel from "shared/components/CardsCarousel"
// constants
import { PAGE_SIZE } from "constants/paginations"
// types
import { CardsProps } from "./types"

const Cards = ({ category, filterIds, additionalTitle, sx }: CardsProps) => {
  const { isVisible, containerRef } = useElementVisible()
  const { coursesCards, isLoading } = useCourses({
    specialty: category,
    filterIds,
    enabled: isVisible,
    specialtiesToShowOnly: ["journal"],
  })

  return (
    <Box sx={sx} ref={containerRef}>
      <CardsCarousel
        isLoading={isLoading}
        cards={coursesCards.slice(0, PAGE_SIZE)}
        title={additionalTitle || category}
        showAllUrl={detailPath(category)}
      />
    </Box>
  )
}

export default Cards
