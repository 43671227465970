import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"

type FetchedCourse = {
  id: number
  publishedAt: string
  courseLength: number
  courseLengthText: string
  certified: boolean
  courseName: string
  hashCourseCoverLandscape: string
  coverLandscape: {
    url: string
  }
  cover: {
    url: string
  }
  superCourse: { title: string }
  hashCourseCover: string
  courseType?: string
  cmePointsText: string
  speaker: {
    speakerTitle: string
    speakerFirstName: string
    speakerLastName: string
  }
}

export type Channel = {
  id: number
  courses: FetchedCourse[]
  partnerName: string
  partnerWebsite: string
  partnerContact: string
  partnerLogo: { url: string }
  haveChannel: boolean
  channelDescription: string
  channelBanner: { url: string }
  subscribeButtonColor?: string
  subscribeButtonBackgroundColor?: string
  partnerLogoHash?: string
}

export type GetChannelRequest = {
  partnerName: string
  locale?: Language
}

export type GetChannelResponse = Channel

export type GetChannelFiltersRequest = {
  filterValue?: string
  locale?: Language
  onlyWithChannel?: boolean
}

export type GetChannelFiltersResponse = {
  data: any[]
  meta: {
    pagination: Pagination
  }
}

export type SubscribeChannelRequest = {
  partnerName: string
}

export type SubscribeChannelResponse = { status?: "done" }

export type UnsubscribeChannelRequest = {
  partnerName: string
}

export type UnsubscribeChannelResponse = { status?: "done" }

export const CHANNEL_KEYS = Object.freeze({
  GET_CHANNEL: "getChannel",
  GET_CHANNEL_FILTERS: "getChannelFilters",
  SUBSCRIBE_CHANNEL: "subscribeChannel",
  UNSUBSCRIBE_CHANNEL: "unsubscribeChannel",
})

export const getChannel = async ({ partnerName, locale = "en" }: GetChannelRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      partnerName,
    },
    { encodeValuesOnly: true }
  )

  const url = `/channel?${params}`

  const response = await axios.get<GetChannelResponse>(url)

  return response.data
}

export const getChannelsFilters = async ({ locale = "en", filterValue }: GetChannelFiltersRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      pagination: {
        page: 1,
        pageSize: 99,
      },
      filters: {
        ...(filterValue
          ? {
              partnerName: {
                $containsi: filterValue,
              },
            }
          : {}),
      },
      locale: languagesTransformer[_locale],
      sort: { name: "ASC" },
      populate: {
        partnerLogo: {
          fields: ["url"],
        },
        partnerCardLogo: {
          fields: ["url"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/course-partners?${params}`

  const response = await axios.get<GetChannelFiltersResponse>(url)

  return response.data
}

export const subscribeChannel = async ({ partnerName }: SubscribeChannelRequest) => {
  const params = qs.stringify(
    {
      partnerName,
    },
    { encodeValuesOnly: true }
  )

  const url = `/subscribeChannel?${params}`

  const response = await axios.get<SubscribeChannelResponse>(url)

  return response.data
}

export const unsubscribeChannel = async ({ partnerName }: UnsubscribeChannelRequest) => {
  const params = qs.stringify(
    {
      partnerName,
    },
    { encodeValuesOnly: true }
  )

  const url = `/unsubscribeChannel?${params}`

  const response = await axios.get<UnsubscribeChannelResponse>(url)

  return response.data
}
