import { buildQueryString } from "utils/buildQueryString"
import getPath from "utils/router"

// separate routes
export const HOME = "/home"
export const SEARCH = "/search"
export const STUDY_MONITOR = "/study-monitor"
export const NOTIFICATION = "/notification"
export const USER = "/user"
export const USERST = "/user/:userId"
export const STORYBOOK = "/storybook"
export const DETAIL = "/detail/:category"
export const COURSE = "/course/:courseId"
export const COURSE_SHARE = "/course-share/:superCourseTitle"
export const COURSE_EPISODE_SHARE = "/course-share/:superCourseTitle/:superEpisodeTitle"
export const COURSE_PUBLIC = "/course-pub/:superCourseTitle"
export const COURSE_PRIVATE = "/course-priv/:privateUrlId"
export const COURSE_EPISODE = "/course/:courseId?episode=:episodeId"
export const FAVOURITES = "/favourites"
export const PURCHASED = "/purchased"
export const ALL_COURSES = "/allCourses"
export const ALL_EPISODES = "/allEpisodes"
export const ALL_SHORT_UNITS = "/allShortUnits"
export const ALL_INTROS = "/allIntros"
export const TEST = "/test/:testId"
export const SPEAKER = "/speaker/:speakerId"
export const SPEAKER_COURSES = "/speakerCourses/:speakerId"
export const CHANNEL = "/channel/:partnerName"
export const SETTINGS = "/settings"
export const WATCHED = "/watched"
export const VERIFICATION_ERROR = "/verificationError"
// auth routes
export const AUTH = "/auth"
export const ENTRY_POINT = `${AUTH}/entryPoint`
export const LOGIN = `${AUTH}/login`
export const REGISTRATION = `${AUTH}/registration`
export const EMAIL_CONFIRM = `${AUTH}/emailConfirm`
export const CHECK_EMAIL_CONFIRM = `${AUTH}/checkEmailConfirm`
export const FORGOT_PASSWORD = `${AUTH}/forgotPassword`
export const RESET_PASSWORD = `${AUTH}/resetPassword`
export const WELCOME = `${AUTH}/welcome`
// onboarding
export const ONBOARDING = `/onboarding`
export const PERSONAL_DATA = `${ONBOARDING}/personalData`
export const PROFESSIONAL_DATA = `${ONBOARDING}/professionalData`
export const PROFILE_IMAGE = `${ONBOARDING}/profileImage`
// settings routes
export const PROFILE = SETTINGS + "/profile"
export const CME = SETTINGS + "/cme"
export const MEMBERSHIP = SETTINGS + "/membership"
export const PASSWORD = SETTINGS + "/password"
export const SAFETY = SETTINGS + "/safety"
export const LANGUAGE = SETTINGS + "/language"
export const NOTIFICATIONS = SETTINGS + "/notifications"
export const REPORT = SETTINGS + "/report"
export const TERMS = SETTINGS + "/terms"
export const DATA_PROTECTION = SETTINGS + "/dataProtection"
export const IMPRINT = SETTINGS + "/imprint"
// getters
export const userPath = (userId: string) => getPath(USERST, { userId })
export const detailPath = (category: string) => getPath(DETAIL, { category })
export const coursePath = (courseId: number) => getPath(COURSE, { courseId })
export const courseSharePath = (superCourseTitle: string, superEpisodeTitle?: string) =>
  superEpisodeTitle
    ? getPath(COURSE_EPISODE_SHARE, { superCourseTitle, superEpisodeTitle })
    : getPath(COURSE_SHARE, { superCourseTitle })
export const coursePublicPath = (superCourseTitle: string) => getPath(COURSE_PUBLIC, { superCourseTitle })
// export const coursePrivatePath = (privateUrlId: string) => getPath(COURSE_PRIVATE, { privateUrlId })

export const testPath = (testId: number) => getPath(TEST, { testId })
export const courseEpisodePath = (courseId: number, episodeId: number) =>
  getPath(COURSE_EPISODE, { courseId, episodeId })
export const speakerPath = (speakerId: number) => getPath(SPEAKER, { speakerId })
export const speakerCoursesPath = (speakerId: number | string, name: string) =>
  getPath(SPEAKER_COURSES, { speakerId }) + `?${buildQueryString({ name })}`
export const channelPath = (partnerName: string) => getPath(CHANNEL, { partnerName })

export const allCourses = (filterValue?: string) => `${ALL_COURSES}?${buildQueryString({ filterValue })}`
export const allIntros = (filterValue?: string) => `${ALL_INTROS}?${buildQueryString({ filterValue })}`
export const allEpisodes = (filterValue?: string) => `${ALL_EPISODES}?${buildQueryString({ filterValue })}`
export const allShortUnits = (filterValue?: string) => `${ALL_SHORT_UNITS}?${buildQueryString({ filterValue })}`
