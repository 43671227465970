import { Box } from "@mui/material"
import { SwiperSlide } from "swiper/react"
// components
import Carousel from "shared/components/Carousel"
import { CourseCard } from "shared/components/cards"
// constants
import { channelPath } from "constants/routes"
// types
import { ChannelsCarouselProps } from "./types"

const SpecialtiesCarousel = ({ title, channels }: ChannelsCarouselProps) => {
  return (
    <Box>
      <Carousel title={title}>
        {channels?.map(({ partnerName, partnerLogo, partnerLogoHash, haveChannel, partnerWebsite }) => (
          <SwiperSlide key={partnerName}>
            <CourseCard
              redirectUrl={haveChannel ? channelPath(partnerName) : partnerWebsite}
              variant="specialty"
              backgroundImage={partnerLogo}
              backgroundImageHash={partnerLogoHash}
              // title={partnerName}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </Box>
  )
}

export default SpecialtiesCarousel
