import {
  Back,
  ArrowUp,
  Check,
  Calendar,
  ChevronBottom,
  ChevronLeft,
  ChevronLeftLong,
  ChevronRight,
  ChevronRightLong,
  Close,
  CloseCircle,
  FullScreenLarge,
  Fullscreen,
  Help,
  Home,
  InPictureLarge,
  InfoBackground,
  Info,
  Inscreen,
  Language,
  LanguageLarge,
  Logo,
  LogoName,
  LogoDark,
  Notification,
  NotificationActive,
  Monitor,
  Pause,
  PauseLarge,
  Play,
  PlayLarge,
  Profile,
  Share,
  Speed,
  SpeedLarge,
  Test,
  ThumbDown,
  ThumbUp,
  Volume,
  VolumeLarge,
  Basket,
  Watchlist,
  WatchlistFilled,
  Search,
  CheckCircleFilled,
  Delete,
  Achievement,
  Lock,
  Safety,
  Book,
  Protection,
  Star,
  Download,
  EyeClosed,
  EyeOpened,
  File,
  AddImage,
  CheckOutlined,
  Logout,
  ShareLink,
  Linkedin,
} from "assets/icons"

const MEDIUM_ICONS = Object.freeze({
  back: Back,
  arrowUp: ArrowUp,
  check: Check,
  calendar: Calendar,
  chevronBottom: ChevronBottom,
  chevronLeft: ChevronLeft,
  chevronLeftLong: ChevronLeftLong,
  chevronRight: ChevronRight,
  chevronRightLong: ChevronRightLong,
  close: Close,
  closeCircle: CloseCircle,
  fullscreen: Fullscreen,
  help: Help,
  home: Home,
  infoBackground: InfoBackground,
  info: Info,
  inscreen: Inscreen,
  language: Language,
  logo: Logo,
  logoName: LogoName,
  logoDark: LogoDark,
  notification: Notification,
  notificationActive: NotificationActive,
  monitor: Monitor,
  pause: Pause,
  play: Play,
  profile: Profile,
  share: Share,
  speed: Speed,
  test: Test,
  thumbDown: ThumbDown,
  thumbUp: ThumbUp,
  volume: Volume,
  basket: Basket,
  watchlist: Watchlist,
  watchlistFilled: WatchlistFilled,
  delete: Delete,
  search: Search,
  checkCircleFilled: CheckCircleFilled,
  achievement: Achievement,
  lock: Lock,
  safety: Safety,
  book: Book,
  protection: Protection,
  star: Star,
  download: Download,
  eyeClosed: EyeClosed,
  eyeOpened: EyeOpened,
  file: File,
  addImage: AddImage,
  checkOutlined: CheckOutlined,
  logout: Logout,
  shareLink: ShareLink,
  linkedin: Linkedin,
})

const LARGE_ICONS = Object.freeze({
  fullScreenLarge: FullScreenLarge,
  inPictureLarge: InPictureLarge,
  languageLarge: LanguageLarge,
  pauseLarge: PauseLarge,
  playLarge: PlayLarge,
  speedLarge: SpeedLarge,
  volumeLarge: VolumeLarge,
})

const ICON_MAPPINGS = Object.freeze({
  medium: MEDIUM_ICONS,
  large: LARGE_ICONS,
})

const MEDIUM_ICONS_NAMES = Object.freeze(Object.keys(MEDIUM_ICONS))
const LARGE_ICONS_NAMES = Object.freeze(Object.keys(LARGE_ICONS))

export { ICON_MAPPINGS, MEDIUM_ICONS, LARGE_ICONS, MEDIUM_ICONS_NAMES, LARGE_ICONS_NAMES }
