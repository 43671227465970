import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
// api
import { COURSE_KEYS, getSpecialties, GetSpecialtiesResponse } from "api"
// types
import { Language } from "types/i18n"

export const useSpecialties = (locale: Language, withCoursesOnly?: boolean, withCoursesTypesOnly?: string[]) => {
  const { data, isLoading } = useQuery<GetSpecialtiesResponse>(
    [COURSE_KEYS.SPECIALTIES, locale, withCoursesOnly, withCoursesTypesOnly],
    () => getSpecialties({ locale, withCoursesOnly, withCoursesTypesOnly })
  )

  const specialties = useMemo(
    () =>
      data?.data.map(({ id, attributes: { name } }) => ({
        id,
        name,
      })),
    [data]
  )

  return { specialties, isLoading }
}
