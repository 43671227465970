import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
// api
import { COURSE_KEYS, getNewestCourses, GetNewestCoursesResponse } from "api"
// utils
import { addMonths, isBefore } from "utils/date"
// constants
import { COURSE_TYPE_TRANSFORMER, newTag } from "constants/courses"
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// types
import { TagsSetTag } from "shared/components/cards/components/TagsSet/types"
import { Language } from "types/i18n"
import { Card } from "utils/cards"

export const useNewestCourses = (): { newestCoursesCards: Card[] | []; isLoading: boolean } => {
  const { t, i18n } = useTranslation()

  const { data: newestCourses, isLoading: isCoursesLoading } = useQuery<GetNewestCoursesResponse>({
    queryKey: [COURSE_KEYS.NEWEST_COURSES, i18n.language],
    queryFn: () =>
      getNewestCourses({ locale: i18n.language as Language, coursesLimit: 10, withCoursesTypesOnly: ["journal"] }),
  })

  const courseCards: Card[] = useMemo(
    () =>
      newestCourses?.data?.map((_course) => {
        if (!_course) return {} as Card

        const { courseName, courseType, publishedAt, courseLengthText, hashCourseCover, cover } = _course.attributes

        const isNewCourse = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())
        const topTags: TagsSetTag[] = [{ title: courseLengthText, variant: "primary" }]
        const bottomTags: TagsSetTag[] = [
          {
            title: t(`shared:courseTypes:${courseType}`),
            variant: "primary",
          },
        ]

        if (isNewCourse) {
          topTags.unshift(newTag(t))
        }

        return {
          courseId: _course.id,
          title: courseName,
          backgroundImage: REACT_APP_STRAPI_URL + cover?.data?.attributes?.url,
          backgroundImageHash: hashCourseCover,
          topTags,
          bottomTags,
          variant: COURSE_TYPE_TRANSFORMER[courseType as keyof typeof COURSE_TYPE_TRANSFORMER],
        }
      }) || [],
    [newestCourses]
  )

  return {
    isLoading: isCoursesLoading,
    newestCoursesCards: courseCards.filter((i) => i.courseId),
  }
}
