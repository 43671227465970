import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import i18n from "i18n"
import { Language } from "types/i18n"
// components
import MainLayout from "pages/layouts/MainLayout"
import PartnerChannels from "./components/PartnerChannels"
import PageTitle from "shared/components/PageTitle"
import Container from "shared/components/Container"
import GlobalLoading from "shared/components/GlobalLoading"
import Cards from "./components/Cards"
import NewestCourses from "./components/NewestCourses"
// types
import { CustomTheme } from "styles/theme"
// hooks
import { useFilteredChannels } from "./hooks/useFilteredChannels"
import { useSpecialties } from "pages/hooks/useSpecialties"
import MostWatchedCourses from "./components/MostWatchedCourses"

const StudyMonitor = () => {
  const { t } = useTranslation()

  const { isLoading: isChannelsLoading, channels } = useFilteredChannels("")
  const { isLoading: isSpecialtiesLoading, specialties } = useSpecialties(i18n.language as Language, true, ["journal"])

  const isLoading = isChannelsLoading || isSpecialtiesLoading

  const carouselSx = useMemo(
    () => [
      (theme: CustomTheme) => ({
        margin: "0 -40px 70px",
        [theme.breakpoints.down("sm")]: {
          margin: "0 -16px 40px",
        },
      }),
    ],
    []
  )

  return (
    <MainLayout>
      <Container>
        <PageTitle
          backgroundWord={t("studyMonitor:pageTitle:backgroundWord")}
          sx={[
            (theme) => ({
              mt: 13,
              mb: 13,
              [theme.breakpoints.down("sm")]: {
                mb: 11,
              },
            }),
          ]}
        />
        {isLoading && <GlobalLoading wrapped />}
        {!isLoading && (
          <>
            <PartnerChannels sx={carouselSx} channels={channels} />
            <NewestCourses
              sx={[
                (theme) => ({
                  margin: "0 -40px 70px",
                  [theme.breakpoints.down("sm")]: {
                    margin: "0 -16px 40px",
                  },
                }),
              ]}
            />
            <MostWatchedCourses
              sx={[
                (theme) => ({
                  margin: "0 -40px 70px",
                  [theme.breakpoints.down("sm")]: {
                    margin: "0 -16px 40px",
                  },
                }),
              ]}
            />
            {specialties?.map(({ name }) => (
              <Cards
                key={name}
                sx={[
                  (theme) => ({
                    margin: "0 -40px 70px",
                    [theme.breakpoints.down("sm")]: {
                      margin: "0 -16px 40px",
                    },
                  }),
                ]}
                category={name}
              />
            ))}
          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default StudyMonitor
