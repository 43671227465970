// styles
import { customStyled } from "styles/theme"

export const KalturaPlayerWrapper = customStyled("div")<{ isDisabled?: boolean }>(
  ({ theme: { palette, typography, spacing, breakpoints }, isDisabled }) => ({
    position: "absolute",
    height: "100%",
    width: "100%",

    ".playkit-spinner-container .playkit-spinner span": {
      backgroundColor: palette.green.hyperpop["500"],
    },

    ".playkit-overlay-action, .playkit-gui-area": {
      ...(isDisabled && { display: "none" }),
    },

    ".playkit-player": {
      ".playkit-seek-bar .playkit-progress-bar .playkit-progress": {
        backgroundColor: palette.green.hyperpop["500"],
      },
      ".playkit-bottom-bar": {
        ".playkit-left-controls, .playkit-right-controls": {
          display: "flex",
          alignItems: "center",
          padding: `${spacing(3)} 0 ${spacing(4)}`,
        },
      },

      ".playkit-volume-control-bar ": {
        width: "22px",
        bottom: spacing(6),

        ".playkit-progress": {
          backgroundColor: palette.green.hyperpop["500"],
        },
      },

      ".playkit-control-button, .playkit-control-button i": {
        width: "18px",
        height: "18px",
      },

      ".playkit-time-display": {
        ...typography.description,
        padding: `0 ${spacing(3)}`,
      },
      ".playkit-badge-icon:after": {
        width: "10px",
        height: "10px",
      },
    },

    ".playkit-dropdown-menu .playkit-dropdown-menu-item.playkit-active": {
      color: palette.green.hyperpop["500"],
    },

    ".playkit-player.playkit-metadata-loaded.playkit-hover:not(.playkit-overlay-active) .playkit-bottom-bar, .playkit-player.playkit-state-paused:not(.playkit-overlay-active) .playkit-bottom-bar":
      {
        padding: `0 ${spacing(11)}`,
        [breakpoints.down("lg")]: {
          padding: `0 ${spacing(4)}`,
        },
      },
  })
)
