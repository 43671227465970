import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
// hooks
import { useNewestCourses } from "./hooks/useNewestCourses"
// components
import CardsCarousel from "shared/components/CardsCarousel"
// types
import { NewestCoursesProps } from "./types"

const NewestCourses = ({ sx }: NewestCoursesProps) => {
  const { t } = useTranslation()
  const { isLoading, newestCoursesCards } = useNewestCourses()

  if (!newestCoursesCards.length && !isLoading) return null

  return (
    <Box sx={sx}>
      <CardsCarousel isLoading={isLoading} cards={newestCoursesCards} title={t("studyMonitor:newestCourses:title")} />
    </Box>
  )
}

export default NewestCourses
