import { useEffect, useRef, useState } from "react"

type Props = { threshold?: number }

export const useElementVisible = ({ threshold = 0 }: Props = {}) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (typeof window.IntersectionObserver === "undefined") {
      setIsVisible(true)
      return
    }

    const current = containerRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!!entry?.isIntersecting)
      },
      { threshold }
    )

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [containerRef])

  return { containerRef, isVisible }
}
