import { ReactNode, useCallback, useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
// services
import { getToken, removeToken, setToken } from "services/auth"
import i18n from "i18n"
// context
import AuthenticationContext from "./context"
// hooks
import useStrapiUser from "./hooks/useStrapiUser"
import useKalturaSession from "./hooks/useKalturaSession"
// components
import GlobalLoading from "shared/components/GlobalLoading"
// types
import { User } from "types/user"

type AuthenticationProviderProps = {
  children: ReactNode
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [authToken, setAuthToken] = useState(getToken())
  const [kalturaTokenState, setKalturaTokenState] = useState("")
  const queryClient = useQueryClient()
  const { user, setUser, isInitialLoading, refetchUser } = useStrapiUser(authToken)

  const { kalturaToken, handleSetKalturaToken, handleRemoveKalturaToken } = useKalturaSession()

  useEffect(() => {
    setKalturaTokenState(kalturaToken)
  }, [kalturaToken])

  const handleAddAuthToken = useCallback(
    (token: string, email?: string) => {
      setAuthToken(token)
      setToken(token)
      handleSetKalturaToken({ userId: (email || user.email) as string })
    },
    [setToken, user]
  )

  const handleRemoveAuthToken = useCallback(() => {
    setAuthToken("")
    removeToken()
    setUser({})
    handleRemoveKalturaToken()
    queryClient.clear()
    location.reload()
  }, [queryClient])

  useEffect(() => {
    if (user.locale) {
      i18n.changeLanguage(user.locale as string)
    }

    if (user.name) {
      // @ts-ignore
      window._paq?.push(["setUserId", user.name]) // set user id for Matomo tracking
    }
  }, [user.locale, user.name])

  if (isInitialLoading) {
    return <GlobalLoading />
  }

  return (
    <AuthenticationContext.Provider
      value={{
        setAuthToken: handleAddAuthToken,
        handleRemoveAuthToken,
        authToken,
        kalturaToken: kalturaTokenState,
        user: user as User,
        setUser,
        refetchUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
