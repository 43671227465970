import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { memo } from "react"
// components
import ChannelsCarousel from "./components/ChannelsCarousel/ChannelsCarousel"
// types
import { ChannelsProps } from "./types"

const Channels = ({ sx, channels }: ChannelsProps) => {
  const { t } = useTranslation()

  if (!channels?.length) return null

  return (
    <Box sx={sx}>
      <ChannelsCarousel title={t("studyMonitor:channels:title")} channels={channels} />
    </Box>
  )
}

export default memo(Channels)
