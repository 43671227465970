import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
// api
import { CHANNEL_KEYS, getChannelsFilters, GetChannelFiltersResponse } from "api"
// constants
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// types
import { Language } from "types/i18n"

export type ChannelCard = {
  id: number
  partnerName: string
  haveChannel: boolean
  partnerWebsite?: string
  partnerLogoHash?: string
  partnerLogo?: string
}

export const useFilteredChannels = (filterValue?: string) => {
  const { i18n } = useTranslation()
  const { data, isLoading } = useQuery<GetChannelFiltersResponse>({
    queryKey: [CHANNEL_KEYS.GET_CHANNEL_FILTERS, i18n.language, filterValue],
    queryFn: () => getChannelsFilters({ locale: i18n.language as Language, filterValue }),
  })

  const channels = useMemo(
    () =>
      data?.data.map(
        ({
          id,
          attributes: { partnerName, partnerLogoHash, partnerLogo, partnerCardLogo, partnerWebsite, haveChannel },
        }) => ({
          id,
          partnerName,
          partnerLogoHash,
          partnerWebsite,
          haveChannel,
          partnerLogo:
            REACT_APP_STRAPI_URL +
            (partnerCardLogo?.data?.attributes
              ? partnerCardLogo?.data?.attributes.url
              : partnerLogo?.data?.attributes.url),
        })
      ),
    [data]
  )

  return { channels, isLoading }
}
