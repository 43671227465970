import { useCallback, useRef } from "react"

type Props = {
  callInterrupter?: boolean
  conditionForCall?: boolean
  callFn?: () => void
}

export const useIntersectingCall = ({ callInterrupter, conditionForCall, callFn }: Props = {}) => {
  const observer = useRef<any>()
  return useCallback(
    (node: any) => {
      if (callInterrupter) return

      if (observer.current) observer?.current?.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && conditionForCall && typeof callFn === "function") {
          callFn()
        }
      })
      if (node) observer.current.observe(node)
    },
    [callInterrupter, conditionForCall]
  )
}
