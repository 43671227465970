import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
// api
import { COURSE_KEYS, getMostWatchedCourses, GetMostWatchedCoursesResponse } from "api"
// utils
import { addMonths, isBefore } from "utils/date"
// constants
import { COURSE_TYPE_TRANSFORMER, newTag } from "constants/courses"
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// types
import { TagsSetTag } from "shared/components/cards/components/TagsSet/types"
import { Language } from "types/i18n"
import { Card } from "utils/cards"

const COURSES_CARDS_LIMIT = 10

export const useMostWatchedCourses = (): { mostWatchedCards: Card[] | []; isLoading: boolean } => {
  const { t, i18n } = useTranslation()

  const { data: mostWatchedCourses, isLoading: isCoursesLoading } = useQuery<GetMostWatchedCoursesResponse>({
    queryKey: [COURSE_KEYS.MOST_WATCHED_COURSES, i18n.language],
    queryFn: () => getMostWatchedCourses({ locale: i18n.language as Language, withCoursesTypesOnly: ["journal"] }),
  })

  mostWatchedCourses &&
    (mostWatchedCourses.data = mostWatchedCourses.data
      .sort(
        (a, b) =>
          (b?.attributes?.superCourse?.data?.attributes?.watchedCount || 0) -
          (a?.attributes?.superCourse?.data?.attributes?.watchedCount || 0)
      )
      .slice(0, COURSES_CARDS_LIMIT))

  const courseCards: Card[] = useMemo(
    () =>
      mostWatchedCourses?.data?.map((_course) => {
        if (!_course) return {} as Card

        const { courseName, courseType, publishedAt, courseLengthText, hashCourseCover, cover } = _course.attributes

        const isNewCourse = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())
        const topTags: TagsSetTag[] = [{ title: courseLengthText, variant: "primary" }]
        const bottomTags: TagsSetTag[] = [
          {
            title: t(`shared:courseTypes:${courseType}`),
            variant: "primary",
          },
        ]

        if (isNewCourse) {
          topTags.unshift(newTag(t))
        }

        return {
          courseId: _course.id,
          title: courseName,
          backgroundImage: REACT_APP_STRAPI_URL + cover?.data?.attributes?.url,
          backgroundImageHash: hashCourseCover,
          topTags,
          bottomTags,
          variant: COURSE_TYPE_TRANSFORMER[courseType as keyof typeof COURSE_TYPE_TRANSFORMER],
        }
      }) || [],
    [mostWatchedCourses]
  )

  return {
    isLoading: isCoursesLoading,
    mostWatchedCards: courseCards.filter((i) => i.courseId),
  }
}
