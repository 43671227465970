import { PlayerStatuses } from "./kaltura-player-context"
//hooks
import { useLoadPlayer } from "./hooks/use-load-player"
//styles
import { KalturaPlayerWrapper } from "./styles"

export interface KalturaPlayerProps {
  /**
   * Entry Id, playable media entry id.
   */
  entryId: string
  /**
   * Autoplay. Indicating if the auto play selected media
   * @default true
   */
  autoplay: boolean
  /**
   * OnPlayerLoaded event handler. Will be called after all player bundler scripts were loaded
   * @param {entryId: string, playerId: string}
   */
  onPlayerLoaded?: (data: { entryId: string; playerId: string }) => void
  /**
   * OnMediaLoaded event handler. Will be called after media entry was successful loaded in player
   * @param entryId
   */
  onMediaLoaded?: (entryId: string) => void
  /**
   * onPlayerLoadingError event handler. Will be called after a player loading related error
   * @param error
   */
  onPlayerLoadingError?: (entryId: string) => void
  /**
   * onMediaLoadingError event handler. Will be called after a media loading related error
   * @param error
   */
  onMediaLoadingError?: (entryId: string) => void

  onEndPlaying?: () => void

  onStartPlaying?: () => void

  /**
   * customize player config before player setup
   */
  customizeConfig?: (config: Record<string, any>) => Record<string, any>
  isDisabled?: boolean
}

export const PlayerErrorMessage = "Oops, failed to load the player"

export const KalturaPlayer = ({
  entryId,
  autoplay,
  onPlayerLoadingError,
  onPlayerLoaded,
  customizeConfig,
  onMediaLoadingError,
  onMediaLoaded,
  onEndPlaying,
  onStartPlaying,
  isDisabled,
}: KalturaPlayerProps) => {
  const { playerId, playerStatus } = useLoadPlayer({
    autoplay,
    entryId,
    onPlayerLoadingError,
    onPlayerLoaded,
    onMediaLoadingError,
    onMediaLoaded,
    onEndPlaying,
    customizeConfig,
    onStartPlaying,
    enableKavaAnalytics: true,
  })

  return (
    <>
      {playerStatus === PlayerStatuses.Error || !playerId ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <div
            style={{
              width: "100%",
              fontSize: "15px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAlign: "center",
              color: "#434a4b",
            }}
          >
            {PlayerErrorMessage}
          </div>
        </div>
      ) : (
        <KalturaPlayerWrapper id={playerId} isDisabled={isDisabled} />
      )}
    </>
  )
}

KalturaPlayer.defaultProps = {
  autoplay: false,
  seek: 0,
}
